<template>
  <div>
    <div class="d-flex justify-content-between">
      <!-- <h4>กันวงเงิน</h4> -->
    </div>
    <b-row>
      <b-col md="3">
        <h4>ออกใบแจ้งกันวงเงิน</h4>
        <b-card>
          <b-form @submit.prevent="onSubmit">
            <b-form-group class="d-flex">
              <b-form-checkbox
                v-model="showRentalAmount"
                id="rentalAmountCheck"
                class="mr-3 mb-1"
              >
                ค่าเช่า
              </b-form-checkbox>
              <b-form-input
                v-if="showRentalAmount"
                type="text"
                id="rentalAmount"
                v-model="rentalAmount"
                class="flex-grow-1"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="d-flex">
              <b-form-checkbox
                v-model="showDepositAmount"
                id="depositAmountCheck"
                class="mr-3 mb-1"
              >
                ค่าประกัน
              </b-form-checkbox>
              <b-form-input
                v-if="showDepositAmount"
                type="text"
                id="depositAmount"
                v-model="depositAmount"
                class="flex-grow-1"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Gateway Providers">
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="gateWay"
                  name="some-radios5"
                  value="omise"
                >
                  Omise
                </b-form-radio>
                <b-form-radio
                  v-model="gateWay"
                  name="some-radios5"
                  value="stripe"
                >
                  Stripe
                </b-form-radio>
              </div>
            </b-form-group>

            <b-button type="submit" variant="primary">Submit</b-button>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="9">
        <h4>รายการกันวงเงิน</h4>
        <b-table
          :fields="fields"
          :items="info.card_deposit"
          class="mb-0"
          :responsive="true"
        >
          <template #cell(deposit_price)="data">
            {{ data.item.deposit_price | number }} <br />
            <b-badge v-if="data.item.deposit_paid" variant="success">
              กันวงเงินแล้ว </b-badge
            >&nbsp;
            <b-badge v-if="data.item.deposit_reversed" variant="warning">
              คืนวงเงินแล้ว
            </b-badge>
            <b-badge
              v-if="!data.item.deposit_paid && data.item.deposit_price > 0"
              variant="danger"
            >
              ยังไม่ได้กันวงเงิน
            </b-badge>
            <div v-if="data.item.deposit_stripe_res">
              {{ data.item.deposit_stripe_res.id }}
              {{ data.item.deposit_stripe_res.receipt_email }}
              {{ data.item.deposit_stripe_res.status }}
            </div>
          </template>
          <template #cell(rental_price)="data">
            {{ data.item.rental_price | number }} <br />
            <b-badge v-if="data.item.rental_paid" variant="success">
              จ่ายค่าเช่าแล้ว
            </b-badge>
            <b-badge
              v-if="!data.item.rental_paid && data.item.rental_price > 0"
              variant="danger"
            >
              ยังไม่ได้จ่ายค่าเช่า
            </b-badge>
            <br />
            <div v-if="data.item.rental_stripe_res">
              {{ data.item.rental_stripe_res.id }}
              {{ data.item.rental_stripe_res.receipt_email }}
              {{ data.item.rental_stripe_res.status }}
            </div>
          </template>
          <template #cell(action)="data">
            <div class="d-flex justify-content-left">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="relief-info"
                class="btn-icon mr-1"
                @click="openQR(info, data)"
              >
                <feather-icon icon="MinimizeIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="relief-success"
                class="btn-icon mr-1"
                @click="openPayment(info, data)"
              >
                <feather-icon icon="LinkIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="relief-warning"
                class="btn-icon mr-1"
                v-b-modal.modal-edit-card-deposit
                @click="retrive_charge(data.item)"
              >
                เช็ค
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="relief-danger mr-1"
                class="btn-icon"
                @click="deleteCardDeposit(data.item._id)"
                :disabled="hasPayment(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="relief-warning"
                class="btn-icon mr-1"
                @click="reviseDeposit(data.item)"
                v-if="
                  !data.item.deposit_reversed &&
                  data.item.deposit_price > 0 &&
                  data.item.deposit_paid
                "
              >
                คืนวงเงิน
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <om-retrive-modal :info="om_retrive_modal" :loading="om_retrive_loading">
    </om-retrive-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import OmRetriveModal from "./EditCardDepositModal.vue";

import {
  BFormRadio,
  BContainer,
  BFormInput,
  BFormGroup,
  BButton,
  BCard,
  BCol,
  BRow,
  BImg,
  BFormCheckbox,
  BForm,
  BTable,
  VBModal,
  BBadge,
} from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BContainer,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCol,
    BRow,
    BImg,
    BButton,
    BFormCheckbox,
    BTable,
    VBModal,
    OmRetriveModal,
    BFormRadio,
  },
  props: ["info"],
  directives: {
    Ripple,
  },
  mounted() {},
  data() {
    return {
      gateWay: "omise",
      showRentalAmount: false,
      rentalAmount: 0,
      showDepositAmount: false,
      depositAmount: 0,
      fields: [
        // { key: "url_hash", label: "URL" },
        { key: "gateway", label: "Gateway" },
        { key: "rental_price", label: "ค่าเช่า" },
        { key: "deposit_price", label: "เงินวางประกัน", width: 0.8 },
        { key: "action", label: "Action", width: 0.1 },
      ],
      items_list: [],
      om_retrive_modal: {},
      om_retrive_loading: false,
    };
  },
  methods: {
    reviseDeposit(id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("คืนวงเงิน", {
          title: "ยืนยันการคืนวงเงิน !!!",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            console.log("คืนวงเงิน", id);
            this.$http({
              method: "POST",
              url: `/order/card-deposit/revise`,
              data: {
                deposit_om_token: id.deposit_om_token,
                card_deposit_id: id._id,
              },
            }).then((x) => {
              this.$root.$emit("order-reload");
            });
          }
        });
    },
    retrive_charge(x) {
      this.om_retrive_loading = true;
      const temp = {
        deposit_charge_id: x.deposit_om_token,
        rental_charge_id: x.rental_om_token,
        order_id: this.info._id,
        url_hash: x.url_hash,
      };
      this.$http({
        method: "POST",
        url: `/order/card-deposit/retrive`,
        data: temp,
      })
        .then((y) => {
          this.om_retrive_loading = false;
          this.om_retrive_modal = y.data.data;
        })
        .catch((e) => {
          this.om_retrive_loading = false;
          this.om_retrive_modal = e.response.data;
          console.log("e", e.response.data);
        });
    },
    openPayment(info, data) {
      const gateway = data.item.gateway;
      if (gateway == "stripe") {
        window.open(
          `http://localhost:3000/payment/credit-card/${info.order_id}-${data.item.url_hash}`,
          "_blank"
        );
      } else if (gateway == "omise") {
        window.open(
          // `http://web.goprolineup.com/payment/credit-card/${info.order_id}-${data.item.url_hash}`,
          `https://www.goprolineup.com/payment/credit-card/${info.order_id}-${data.item.url_hash}`,
          "_blank"
        );
      }
    },
    openQR(info, data) {
      window.open(
        `https://web.lenslineup.com/payment/credit-card/qr/${info.order_id}-${data.item.url_hash}`,
        "_blank"
      );
    },

    deleteCardDeposit(deposit_item_id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ลบรายการกันวงเงิน", {
          title: "ยืนยันลบ !!!",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            console.log("deposit_item_id", deposit_item_id);
            this.$http({
              method: "DELETE",
              url: `/order/card-deposit/${deposit_item_id}`,
            }).then((x) => {
              this.$root.$emit("order-reload");
            });
          }
        });
    },
    clear() {
      this.showRentalAmount = false;
      this.rentalAmount = 0;
      this.showDepositAmount = false;
      this.depositAmount = 0;
    },
    onSubmit(x) {
      const temp = {
        order_id: this.$route.params.id,
      };
      if (this.showRentalAmount === true || this.showDepositAmount === true) {
        if (this.showRentalAmount) {
          Object.assign(temp, { rental_price: this.rentalAmount });
        }
        if (this.showDepositAmount) {
          Object.assign(temp, { deposit_price: this.depositAmount });
        }
        Object.assign(temp, { gateway: this.gateWay });
        this.$http({
          method: "POST",
          url: `/order/card-deposit/`,
          data: temp,
        })
          .then(async (x) => {
            await this.$root.$emit("order-reload");
            this.clear();
          })
          .catch((e) => e);
      }
    },
    /// เช็คว่ามีรายการชำระเงินแล้ว
    hasPayment(x) {
      if (x.deposit_paid || x.rental_paid) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
